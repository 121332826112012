<template>
  <el-input :type="type" ref="inputPassword" class="input-password" v-model="inputValue" :disabled="disabled" :placeholder="placeholder">
    <i slot="suffix" class="iconfont" @click="type = 'text'" v-if="type === 'password' && showPassword">&#xe6b9;</i>
    <i slot="suffix" class="iconfont" @click="type = 'password'" v-if="type === 'text' && showPassword">&#xe6ba;</i>
  </el-input>
</template>

<script>
export default {
  name: "InputPassword",
  props: {
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    showPassword: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  watch: {

  },
  data() {
    return {
      type: "password"
    };
  },
  mounted() {
    // this.type = "password";
    // setTimeout(() => {
    //   console.log(this.value);
    //   if (!this.value && this.showPassword && this.$refs.inputPassword) {
    //     this.$refs.inputPassword.$el.querySelector("input").value = "";
    //     this.inputValue = "";
    //   }
    // }, 800);
  }
};
</script>

<style lang="scss">
.input-password {
  .iconfont {
    font-size: 20px;
    cursor: pointer;
    color: #a9b5c6;
    margin-right: 4px;
  }
}
</style>
