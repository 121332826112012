var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-input",
    {
      ref: "inputPassword",
      staticClass: "input-password",
      attrs: {
        type: _vm.type,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
      },
      model: {
        value: _vm.inputValue,
        callback: function ($$v) {
          _vm.inputValue = $$v
        },
        expression: "inputValue",
      },
    },
    [
      _vm.type === "password" && _vm.showPassword
        ? _c(
            "i",
            {
              staticClass: "iconfont",
              attrs: { slot: "suffix" },
              on: {
                click: function ($event) {
                  _vm.type = "text"
                },
              },
              slot: "suffix",
            },
            [_vm._v("")]
          )
        : _vm._e(),
      _vm.type === "text" && _vm.showPassword
        ? _c(
            "i",
            {
              staticClass: "iconfont",
              attrs: { slot: "suffix" },
              on: {
                click: function ($event) {
                  _vm.type = "password"
                },
              },
              slot: "suffix",
            },
            [_vm._v("")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }